/* eslint-disable no-console */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { goBack, push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { jwtDecode } from 'jwt-decode';

import { strings } from '../strings/StringsProvider';
import ActionDetailStat from './ActionDetailStat';
import './ActionDetail.scss';
import { getSpecificMentorAction, deleteMentorAction, changeMentorComment } from '../actions/mentor.actions';
import {
  getSpecificSupervisorAction,
  deleteSupervisorAction,
  changeSupervisorComment,
} from '../actions/supervisor.actions';
import { deepSum, toString } from 'tools/utils';

function ActionDetail({ match }) {
  const router = useSelector((state) => state.router);

  const userRole = jwtDecode(localStorage.getItem('access-token')).role;
  const adminMentor = useRef(router.location.pathname.startsWith('/admin/'));
  const adminSupervisor = useRef(router.location.pathname.startsWith('/supervisor/'));
  const [deleteDetail, setDeleteDetail] = useState(null);
  const [visibleComment, setVisibleComment] = useState(false);
  const [comment, setComment] = useState('');

  const dispatch = useDispatch();

  const mentorAction = useSelector((state) => state.mentor?.mentorAction);
  const supervisorAction = useSelector((state) => state.supervisor?.supervisorAction);
  const mentorTitle = useSelector((state) =>
    state.mentor?.mentorAction.omama
      ? state.mentor?.mentorAction.id + ' ' + state.mentor?.mentorAction.omamaName
      : state.mentor?.mentorAction.id,
  );
  const supervisorTitle = useSelector((state) =>
    state.supervisor?.supervisorAction.omama
      ? state.supervisor?.supervisorAction.id + ' ' + state.supervisor?.supervisorAction.omamaName
      : state.supervisor?.supervisorAction.id,
  );

  const changePage = (location) => dispatch(push(location));

  useEffect(() => {
    if (userRole === 'mentor' || adminMentor.current) {
      dispatch(getSpecificMentorAction(match?.params?.actionId)).then((e) => {
        if (e.payload.comment) setComment(e.payload.comment);
      });
    } else if (userRole === 'supervisor' || adminSupervisor.current) {
      dispatch(getSpecificSupervisorAction(match?.params?.actionId)).then((e) => {
        if (e.payload.comment) setComment(e.payload.comment);
      });
    }
  }, [dispatch, match.params.actionId, userRole]);

  const deleteMentorActionById = useCallback(
    async (id, callback) => {
      try {
        dispatch(deleteMentorAction(id));
        deleteActionCancel();
        callback();
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch],
  );

  const deleteSupervisorActionById = useCallback(
    (id, callback) => {
      try {
        dispatch(deleteSupervisorAction(id));
        deleteActionCancel();
        callback();
      } catch (error) {
        console.log(error);
      }
    },
    [dispatch],
  );

  const showDeleteAction = () => {
    setDeleteDetail(userRole === 'supervisor' || adminSupervisor.current ? supervisorAction : mentorAction);
  };

  const deleteActionOk = () => {
    if (userRole === 'mentor' || adminMentor.current) {
      deleteMentorActionById(deleteDetail._id, () => {
        dispatch(goBack());
      });
    } else if (userRole === 'supervisor' || adminSupervisor.current) {
      deleteSupervisorActionById(deleteDetail._id, () => {
        dispatch(goBack());
      });
    }
  };

  const deleteActionCancel = () => {
    setDeleteDetail(null);
  };

  const getSummary = useCallback((questions) => {
    const sum = deepSum(questions);
    return `${sum.value}/${sum.max}, ${Math.round((sum.value / (sum.max / 100)) * 100) / 100}%`;
  }, []);

  const changeToPage = (url) => {
    document.getElementsByTagName('body')[0].style.overflow = 'unset';
    changePage(url);
  };

  const changeComment = useCallback(() => {
    supervisorAction?.supervisorID
      ? dispatch(changeSupervisorComment(supervisorAction?._id, comment))
      : dispatch(changeMentorComment(mentorAction?._id, comment));
    setVisibleComment(false);
  }, [comment, dispatch, mentorAction._id, supervisorAction._id, supervisorAction.supervisorID]);

  const commentModalClose = () => {
    if (userRole === 'mentor' || adminMentor.current) {
      dispatch(getSpecificMentorAction(match?.params?.actionId)).then((e) => {
        if (e.payload.comment) setComment(e.payload.comment);
      });
    } else if (userRole === 'supervisor' || adminSupervisor.current) {
      dispatch(getSpecificSupervisorAction(match?.params?.actionId)).then((e) => {
        if (e.payload.comment) setComment(e.payload.comment);
      });
    }
    setVisibleComment(false);
  };

  const isSupervisor = userRole === 'supervisor' || adminSupervisor.current;
  const isSupervision = supervisorAction?.actionKey === 'supervision';
  return (
    <div className="container">
      <div className="container-inner">
        <div className="back" onClick={() => dispatch(goBack())}>
          <LeftOutlined className="icon" /> {strings.backToTheList}
        </div>
        <div className="container-inner-info">
          <ActionDetailStat
            title={`${strings.date}:`}
            firstInput={dayjs(isSupervisor ? supervisorAction?.date : mentorAction?.date).format('DD. MM. YYYY')}
            secondInput={dayjs(isSupervisor ? supervisorAction?.date : mentorAction?.date).format('HH:mm')}
          ></ActionDetailStat>
          <ActionDetailStat
            title={`${strings.name}:`}
            firstInput={isSupervisor ? supervisorTitle : mentorTitle}
          ></ActionDetailStat>
          <ActionDetailStat
            title={strings.timeSpent}
            firstInput={isSupervisor ? toString(supervisorAction?.timeSpent) : toString(mentorAction?.timeSpent)}
          ></ActionDetailStat>
          {isSupervisor && isSupervision && (
            <ActionDetailStat
              title={strings.points}
              firstInput={supervisorAction?.questions && getSummary(supervisorAction.questions)}
            ></ActionDetailStat>
          )}
          {isSupervisor && isSupervision && (
            <div
              className="container-inner-info-showScreeningForm"
              onClick={() => changeToPage(`/supervisor/form/${supervisorAction._id}`)}
            >
              <span>{strings.showForm}</span>
              <RightOutlined className="icon" />
            </div>
          )}
          <div className="container-inner-info-comment">
            <h4 className="container-inner-info-comment-title">{strings.commentWithDoubleDots}</h4>
            <p className="container-inner-info-comment-content">{comment}</p>
          </div>

          <div className="changeCommentContainer">
            <Button shape="round" onClick={() => setVisibleComment(true)}>
              {strings.editComment}
            </Button>
          </div>
          <Modal
            title={strings.editComment}
            open={visibleComment}
            onCancel={commentModalClose}
            cancelText={strings.cancel}
            onOk={changeComment}
            okText={strings.confirm}
            okButtonProps={{
              disabled: comment === (supervisorAction.comment || mentorAction.comment),
            }}
          >
            <textarea
              value={comment || ''}
              className="changeCommentInput"
              onChange={(e) => setComment(e.target.value)}
            />
          </Modal>

          <div className="container-inner-deleteButton">
            <Button style={{ fontSize: '14px' }} className="adminClient-button" onClick={showDeleteAction}>
              {strings.deleteAction}
            </Button>
          </div>
        </div>
        <Modal
          centered
          title={strings.deleteAction}
          open={!!deleteDetail}
          onCancel={deleteActionCancel}
          onOk={deleteActionOk}
        >
          {strings.question}
        </Modal>
      </div>
    </div>
  );
}

ActionDetail.propTypes = {
  match: PropTypes.object,
};

export default ActionDetail;
