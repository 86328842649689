import React from 'react';
import PropTypes from 'prop-types';
import { strings } from '../../strings/StringsProvider';

const ClientActionComment = ({ comment, status, isClub, className }) => {
  if (!comment) {
    return null;
  }

  if (status !== `canceled - ${strings.lessonCanceledClient}`) {
    return (
      <p className={className}>
        <span>{comment}</span>
      </p>
    );
  }

  const cancellationMessage = isClub ? strings.preschoolClubCanceled : strings.lessonCanceled;
  const [mainComment, additionalComment] = comment.split('-').map((part) => part.trim());
  const actionDescription = strings.actionDeletionOptions[mainComment]?.description || mainComment;

  return (
    <p className={className}>
      <span>{`${cancellationMessage} ${actionDescription}`}</span>
      {additionalComment && <span>{additionalComment}</span>}
    </p>
  );
};

ClientActionComment.propTypes = {
  comment: PropTypes.string,
  status: PropTypes.string,
  isClub: PropTypes.bool,
  className: PropTypes.string,
};

export default ClientActionComment;
