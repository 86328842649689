import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';
import { strings } from '../../strings/StringsProvider';

const { TextArea } = Input;

const ActionCommentEditor = ({ initialComment, status, onCommentChange }) => {
  const [editableComment, setEditableComment] = useState('');
  const [nonEditablePart, setNonEditablePart] = useState('');
  const isCancelledAction = status === `canceled - ${strings.lessonCanceledClient}`;

  useEffect(() => {
    if (isCancelledAction) {
      const parts = initialComment ? initialComment.split('-') : [];
      if (parts.length > 1) {
        setNonEditablePart(parts[0].trim());
        setEditableComment(parts.slice(1).join('-').trim());
      } else {
        setNonEditablePart('');
        setEditableComment('');
      }
    } else {
      setNonEditablePart('');
      setEditableComment(initialComment || '');
    }
  }, [isCancelledAction, initialComment]);

  const handleCommentChange = (e) => {
    const newEditableComment = e.target.value;
    setEditableComment(newEditableComment);

    let finalComment = newEditableComment;
    if (isCancelledAction && nonEditablePart) {
      finalComment = `${nonEditablePart} - ${newEditableComment}`;
    }

    onCommentChange(finalComment);
  };

  return (
    <TextArea
      className="action-detail-edit-comment"
      placeholder={strings.comment}
      value={editableComment}
      onChange={handleCommentChange}
      autoSize={{ minRows: 2, maxRows: 6 }}
      data-test-id="admin-actionDetail-edit-comment-input"
    />
  );
};

export default ActionCommentEditor;

ActionCommentEditor.propTypes = {
  initialComment: PropTypes.string,
  status: PropTypes.string,
  onCommentChange: PropTypes.func,
};
