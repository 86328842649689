import React, { useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Dropdown, TimePicker, Button, Input, Select } from 'antd';
import { DownOutlined, CloseOutlined, WarningTwoTone } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { jwtDecode } from 'jwt-decode';
import { api } from '../../conf';
import axios from 'axios';
import dayjs from 'dayjs';
import imageCompression from 'browser-image-compression';
import classNames from 'classnames';

// Local imports
import { getOmamaClientsAction } from '../../actions/omama.actions';
import { strings } from '../../strings/StringsProvider';
import { setLoading } from '../../actions/status.actions';
import './AddNewAction.scss';

import { getOmamasAction } from '../../actions/users.actions';

function AddNewAction({ getOmamasAction, getOmamaClientsAction, setLoading, history, orderedClients, omamas }) {
  const [actionDate, setActionDate] = useState('');
  const [actionTime, setActionTime] = useState(dayjs());
  const [clientKey, setClientKey] = useState('');
  const [client, setClient] = useState(strings.chooseClient);
  const [actionKey, setActionKey] = useState('');
  const [actionName, setActionName] = useState(strings.chooseAction);
  const [typKey, setTypKey] = useState('client');
  const [typ, setTyp] = useState(strings.lesson);
  const [timeSpent, setTimeSpent] = useState(1);
  const [clubKey, setClubKey] = useState('');
  const [clubNames, setClubNames] = useState('');
  const [comment, setComment] = useState('');
  const [base64ImagePhoto, setBase64ImagePhoto] = useState('');
  const [base64ImageThumbnail, setBase64ImageThumbnail] = useState('');
  const [imageDateTaken, setImageDateTaken] = useState(null);

  useEffect(() => {
    getOmamasAction();
    getOmamaClientsAction();
  }, [getOmamasAction, getOmamaClientsAction]);

  const username = jwtDecode(localStorage.getItem('access-token')).username;
  const omama = omamas.find((omama) => omama._id === username);
  const omamaLevel = omama && Number(omama.level);

  const push = (link) => {
    history.push(link);
  };

  const addAction = async () => {
    setLoading(true);
    const redirectUrl = '/omama/plan';

    const time = dayjs(actionTime).format('HH:mm');
    const date = dayjs(actionDate + 'T' + time).format();

    let bodyData;

    switch (typKey) {
      case 'club':
        bodyData = {
          date,
          client: true,
          id: clubKey,
          differentAction: 'club',
          clubIds: clubKey,
          clubNames,
          status: 'active',
          timeSpent: parseFloat(timeSpent),
        };
        break;
      case 'client':
        bodyData = {
          date,
          client: true,
          id: clientKey,
          differentAction: '',
          status: 'active',
          timeSpent: 1.2,
        };
        break;
      default:
        bodyData = {
          date,
          client: false,
          id: actionName,
          differentAction: '',
          status: 'active',
          timeSpent: parseFloat(timeSpent),
          comment,
        };
        break;
    }

    try {
      await axios.post(api.addNewAction, bodyData);
      setLoading(false);
      push(redirectUrl);
    } catch (error) {
      setLoading(false);
    }
  };

  const findLabel = (key, options) => {
    for (const option of options) {
      if (option.key === key) {
        if (typeof option.label === 'string') return option.label;
        if (option.label.props) return option.label.props.children;
      }
      if (option.children) {
        const childLabel = findLabel(key, option.children);
        if (childLabel) return childLabel;
      }
    }
    return '';
  };

  const onTypeChange = (key, item) => {
    setTypKey(key);
    setTyp(item);
  };

  const onClientChange = (key, item) => {
    setClientKey(key);
    setClient(item);
  };

  const onActionChange = ({ key }) => {
    setActionKey(key);
    setActionName(findLabel(key, actionOptions));
  };

  const typOptions = [
    {
      key: 'client',
      label: (
        <a href="#" key="client" onClick={() => onTypeChange('client', strings.lesson)}>
          {strings.lesson}
        </a>
      ),
    },
    {
      key: 'club',
      label: (
        <a href="#" key="club" onClick={() => onTypeChange('club', strings.preschoolClub)}>
          {strings.preschoolClub}
        </a>
      ),
    },
    {
      key: process.env.REACT_APP_LANGUAGE.toLowerCase() === 'cz' ? 'akce' : 'akcia',
      label: (
        <a
          href="#"
          key={process.env.REACT_APP_LANGUAGE.toLowerCase() === 'cz' ? 'akce' : 'akcia'}
          onClick={() =>
            onTypeChange(process.env.REACT_APP_LANGUAGE.toLowerCase() === 'cz' ? 'akce' : 'akcia', strings.action)
          }
        >
          {strings.action}
        </a>
      ),
    },
    {
      key: 'photo',
      label: (
        <a href="#" key="photo" onClick={() => onTypeChange('photo', strings.addPhoto)}>
          {strings.addPhoto}
        </a>
      ),
    },
  ];

  const clientsOptions = Object.values(orderedClients).map((client, index) => ({
    key: index,
    label: (
      <a href="#" key={client._id} onClick={() => onClientChange(client._id, client.name)}>
        {client.lessonsAlarm && <WarningTwoTone twoToneColor="red" />} {client.name}
      </a>
    ),
  }));

  const actionOptions = [
    {
      key: 'work',
      type: 'group',
      label: strings.work,
      children: [
        omamaLevel >= 3
          ? {
              key: 'mentoringSenior',
              label: strings.mentorTillSenior,
            }
          : {
              key: 'mentoring',
              label: strings.mentoring,
            },
        {
          key: 'telefonat',
          label: strings.phoneCall,
        },
        {
          key: 'parentalClub',
          label: strings.parentalClub1,
        },
        {
          key: 'supervision',
          label: strings.supervision,
        },
        {
          key: 'training',
          label: strings.training,
        },
        {
          key: 'interNDA',
          label: strings.interNDA,
        },
        {
          key: 'otherJob',
          label: strings.otherJob,
        },
        {
          key: 'education',
          label: strings.education,
        },
      ],
    },
    {
      key: 'notBeingInJob',
      type: 'group',
      label: strings.notBeingInJob,
      children: [
        {
          key: 'vacation',
          label: strings.vacation,
        },
        {
          key: 'doctor',
          label: strings.doctor,
        },
        {
          key: 'familyDoctor',
          label: strings.familyDoctor,
        },
        {
          key: 'workUnable',
          label: strings.workUnable,
        },
        {
          key: 'OCR',
          label: strings.OCR,
        },
        {
          key: 'extraTimeOff',
          label: strings.extraTimeOff,
        },
        {
          key: 'other',
          label: strings.other,
        },
      ],
    },
  ];

  const checkButton = () => {
    if (typKey === 'client') {
      if (!actionDate || !actionTime || !clientKey) {
        return true;
      }
    } else if (typKey === 'club') {
      if (!actionDate || !actionTime || !clubKey) {
        return true;
      }
    } else if (typKey === strings.action.toLowerCase()) {
      if (!actionDate || !actionTime || !actionName || actionName === strings.chooseAction || !timeSpent) {
        return true;
      }
    }
    return false;
  };

  const compressImage = async (event, useWebWorker = true) => {
    setLoading(true);
    const file = event.target.files[0];
    if (!file) {
      setLoading(false);
      return;
    }

    const reader = new FileReader();
    // TO DO delete
    // reader.onload = () => {
    //   try {
    //     this.setState({ imageDateTaken: file.lastModified });
    //   } catch (error) {
    //     this.setState({ imageDateTaken: null });
    //   }
    // }
    reader.readAsArrayBuffer(file);

    const orientation = await imageCompression.getExifOrientation(file);

    let options = {
      maxSizeMB: 1,
      useWebWorker: useWebWorker,
      exifOrientation: orientation,
    };
    const output = await imageCompression(file, options);
    options = {
      maxSizeMB: 0.2,
      useWebWorker: useWebWorker,
      exifOrientation: orientation,
    };
    const thumbnail = await imageCompression(file, options);

    const imagePhoto = await imageCompression.getDataUrlFromFile(output);
    const imageThumbnail = await imageCompression.getDataUrlFromFile(thumbnail);

    setBase64ImagePhoto(imagePhoto);
    setBase64ImageThumbnail(imageThumbnail);
    setImageDateTaken(file.lastModified || null);

    setLoading(false);
  };

  const uploadImage = async () => {
    setLoading(true);
    const body = {
      photo: base64ImagePhoto,
      thumbnail: base64ImageThumbnail,
      clientID: clientKey,
      actionID: '',
      imageDateTaken,
    };

    try {
      await axios.post(api.addNewPhoto, body);
      setLoading(false);
      setBase64ImagePhoto('');
      setBase64ImageThumbnail('');
      setImageDateTaken(null);
    } catch (error) {
      setLoading(false);
    }
  };

  const checkPhotoButton = () => !base64ImagePhoto || !base64ImageThumbnail || !clientKey;

  const deleteImage = () => () => {
    setBase64ImagePhoto('');
    setBase64ImageThumbnail('');
    setImageDateTaken(null);
  };

  const renderSelectOptions = () => {
    const { Option } = Select;
    const children = [];
    orderedClients.forEach((e) => {
      if (e.predskolskyKlub) {
        children.push(
          <Option className="selectRow" key={e.name + '%%' + e._id}>
            {e.name}
          </Option>,
        );
      }
    });
    return children;
  };

  const handleChange = (value) => {
    const noIndex = value.map((e) => e.split('%%'));
    const ids = noIndex.map((e) => {
      return e[1];
    });
    const names = noIndex
      .map((e) => {
        return e[0];
      })
      .sort()
      .join(', ');

    setClubKey(ids);
    setClubNames(names);
  };

  if (typKey === 'photo') {
    return (
      <div>
        <div style={{ paddingTop: '60px' }}>
          <div className="pridatContainer">
            <div className="typeDropdown" data-test-id="omama-changeActionType-button2">
              <Dropdown trigger={['click']} className="actionsDropdown" menu={{ items: typOptions }} placement="bottom">
                <div>
                  {typ} <DownOutlined />
                </div>
              </Dropdown>
            </div>
            <br />
            <div
              className={classNames('photoButton', {
                disabledPhotoButton: base64ImagePhoto,
              })}
            >
              <label htmlFor="photogallery">
                <img alt="empty" className="photoIcon" src="/images/Icons/Galeria_ikona.png" />
                <div style={{ textAlign: 'center' }}>{strings.gallery}</div>
              </label>
            </div>
            <input
              id="photogallery"
              value={[]}
              type="file"
              accept="image/*"
              onChange={compressImage}
              style={{ display: 'none' }}
            ></input>

            {base64ImageThumbnail.length > 0 && (
              <div className="tempPhotos">
                <div>
                  <img alt="empty" className="clientEndImg" src={base64ImageThumbnail}></img>
                  <div className="xButton" onClick={deleteImage()}>
                    <CloseOutlined />
                  </div>
                </div>
              </div>
            )}
            <br />
            <Dropdown
              trigger={['click']}
              className="actionsDropdown"
              menu={{ items: clientsOptions }}
              placement="bottom"
            >
              <div>
                {client} <DownOutlined />
              </div>
            </Dropdown>
            <br />
            <Button className="addButton" size="large" onClick={uploadImage} disabled={checkPhotoButton()}>
              {strings.add}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mainBox">
      <div style={{ paddingTop: '60px' }}>
        <div className="pridatContainer">
          <div className="typeDropdown" data-test-id="omama-changeActionType-button">
            <Dropdown trigger={['click']} className="actionsDropdown" menu={{ items: typOptions }} placement="bottom">
              <div>
                {typ} <DownOutlined style={{ fontSize: '20px' }} />
              </div>
            </Dropdown>
          </div>
          <br />
          <div className="addNewActionPicker">
            <h3 className="pickerHeader">{strings.date}</h3>
            <Input
              type="date"
              value={actionDate}
              onChange={(e) => setActionDate(e.target.value)}
              className="picker"
              data-test-id="omama-addAction-date-input"
            />
          </div>
          <div className="addNewActionPicker">
            <h3 className="pickerHeader">{strings.time}</h3>
            <br />
            <TimePicker
              defaultValue={dayjs('12:00', 'HH:mm')}
              type="time"
              format={'HH:mm'}
              minuteStep={15}
              needConfirm={false}
              onChange={(value) => setActionTime(value)}
              value={actionTime}
              className="picker"
              placeholder="Vyber čas"
            />
          </div>
          <div className="addNewAction-spentTimePicker">
            <div>{typKey === strings.action.toLowerCase() && strings.spentTime1}</div>
            <br />
            {typKey === strings.action.toLowerCase() && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input
                  type="number"
                  id="timeSpent"
                  onChange={(e) => setTimeSpent(e.target.value)}
                  value={timeSpent}
                  step={0.5}
                  min={0}
                />
                <div>h</div>
              </div>
            )}
          </div>
          <br />
          <br />
          {typKey === strings.action.toLowerCase() && (
            <div>
              <p className="actionHeader">{strings.chooseAction1}</p>
              <div className="dropdownSelect">
                <Dropdown
                  trigger={['click']}
                  menu={{ items: actionOptions, onClick: onActionChange }}
                  placement="bottom"
                >
                  <div>
                    {actionName} <DownOutlined />
                  </div>
                </Dropdown>
              </div>
              {actionKey && (
                <div className="activityFormField">
                  <div>
                    <Input placeholder={strings.comment} value={comment} onChange={(e) => setComment(e.target.value)} />
                  </div>
                </div>
              )}
            </div>
          )}
          {typKey === 'client' && (
            <div className="dropdownSelect" data-test-id="omama-newAction-client-dropdown">
              <Dropdown trigger={['click']} menu={{ items: clientsOptions }} placement="bottom">
                <div>
                  {client} <DownOutlined />
                </div>
              </Dropdown>
            </div>
          )}
          {typKey === 'club' && (
            <div>
              <Select
                mode="multiple"
                allowClear
                popupClassName="selectMenu"
                className="SelectInputMulti"
                placeholder={strings.chooseClients}
                onChange={handleChange}
              >
                {renderSelectOptions()}
              </Select>
            </div>
          )}
          <Button className="addButton" size="large" onClick={addAction} disabled={checkButton()}>
            {strings.add}
          </Button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ omama, menu, users }) => {
  let sortable = [];
  for (let item in omama.clients) {
    sortable.push(omama.clients[item]);
  }

  const orderedClients = sortable.sort((s1, s2) => {
    return s1.lastFirstName.localeCompare(s2.lastFirstName, 'sk', {
      sensitivity: 'accent',
    });
  });

  const filteredClients = orderedClients.filter((client) => client.active === true);

  //to do actions refactor
  return {
    orderedClients: filteredClients,
    showMenu: menu.showMenu,
    omamas: users.omamas,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setLoading,
      getOmamasAction,
      getOmamaClientsAction,
      changePage: (location) => push(location),
    },
    dispatch,
  );

AddNewAction.propTypes = {
  getOmamasAction: PropTypes.func,
  getOmamaClientsAction: PropTypes.func,
  setLoading: PropTypes.func,
  history: PropTypes.object,
  orderedClients: PropTypes.array,
  omamas: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewAction);
