import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './AdminMenu.scss';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { jwtDecode } from 'jwt-decode';
import { CloseOutlined, DownOutlined } from '@ant-design/icons';
import axios from 'axios';
import { api } from 'conf';

// Local imports
import { strings } from '../strings/StringsProvider';
import Base64Image from './Base64Image';
import { getOmamaTokenAction } from '../actions/omama.actions';
import { userLogoutAction } from '../actions/menu.actions';
import { getMissingAccessoriesCount } from 'actions/admin.actions';
import { useAppConfig } from 'context/AppGlobalContext';

const AdminMenu = ({ pathname }) => {
  const { username, role } = jwtDecode(localStorage.getItem('access-token'));
  const isAdmin = role === 'admin';
  const isMentor = role === 'mentor';
  const isSupervisor = role === 'supervisor';
  const [iconClicked, setIconClicked] = useState(false);
  const [headline, setHeadline] = useState('');
  const [notificationStyle, setNotificationStyle] = useState('');
  const [showUserActions, setShowUserActions] = useState(false);
  const didMount = useRef(false);

  const omama = useSelector((state) => state.omama.omama);
  const missingAccessoriesCount = useSelector((state) => state.admin.missingAccessoriesCount);

  const dispatch = useDispatch();

  const changePage = (location) => dispatch(push(location));

  const { isMobile } = useAppConfig();

  const handleOutsideClick = useCallback(() => {
    if (showUserActions) {
      setShowUserActions(false);
    }
  }, [showUserActions]);

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [handleOutsideClick]);

  useEffect(() => {
    if (isAdmin) {
      axios.get(api.getAdminUser(username)).then((userData) => {
        if (userData?.data?.data) {
          const user = userData.data.data;
          if (user.accessoriesCoordinator) {
            dispatch(getMissingAccessoriesCount());
          }
        }
      });
    }
  }, [dispatch, isAdmin, username]);

  const updateNotifications = useCallback(() => {
    if (missingAccessoriesCount) {
      const seenNotifications = JSON.parse(localStorage.getItem('seen-notifications'));
      if (seenNotifications) {
        if (missingAccessoriesCount.count > seenNotifications.count) {
          setNotificationStyle('accessories-notification-new');
        } else {
          const difference = missingAccessoriesCount.ids.filter((item) => !seenNotifications.ids.includes(item));
          if (difference.length > 0) {
            setNotificationStyle('accessories-notification-new');
          } else {
            setNotificationStyle('accessories-notification-seen');
          }
        }
      } else {
        if (missingAccessoriesCount.count > 0) {
          setNotificationStyle('accessories-notification-new');
        }
      }
    }
  }, [missingAccessoriesCount]);

  useEffect(() => {
    if (pathname === '/admin/pomocky' && missingAccessoriesCount) {
      setNotificationStyle('accessories-notification-seen');
      const missingAccessoriesNotification = {
        count: missingAccessoriesCount.count,
        ids: missingAccessoriesCount.ids,
      };
      localStorage.setItem('seen-notifications', JSON.stringify(missingAccessoriesNotification));
    }
  }, [pathname, missingAccessoriesCount]);

  useEffect(() => {
    updateNotifications();
  }, [missingAccessoriesCount, updateNotifications]);

  const getHeadline = useCallback(
    (page) => {
      let headerName = [
        {
          url: '/admin/users',
          name: isAdmin ? strings.users : strings.omamy,
        },
        {
          url: String(page.match(/^\/admin\/user\/.*/)),
          name: isAdmin ? strings.users : strings.omamy,
        },
        { url: '/admin/plan/akcia', name: strings.action },
        { url: '/admin/plan', name: strings.plan },
        { url: '/admin/register', name: strings.users },
        { url: '/admin/user/admin/admin', name: strings.users },
        {
          url: String(page.match(/^\/supervisor\/akcia\/.*/)),
          name: strings.action,
        },
        { url: '/supervisor/plan', name: strings.plan },
        { url: '/admin/klienti', name: strings.clients },
        { url: '/admin/klient', name: strings.clients },
        { url: '/admin/screening', name: strings.screening },
        { url: '/admin/aktivity', name: strings.activities },
        { url: '/admin/vyvinoveStadia', name: strings.developmentStages },
        { url: '/admin/pomocky', name: strings.helperThings },
        { url: '/admin/report', name: strings.report },
        { url: '/admin/nastavenia', name: strings.settings },
        { url: '/admin', name: strings.statistics },
        { url: '/supervisor/form', name: strings.supervisions },
        { url: '/mentor/vykaz', name: strings.workReport },
      ];

      let result = headerName.find((item) => page.startsWith(item.url));
      let headline = result && result.name ? result.name : '';
      setHeadline(headline);
    },
    [isAdmin],
  );

  useEffect(() => {
    dispatch(getOmamaTokenAction());
    getHeadline(pathname);

    // unlisten = history.listen((location) => {
    //   const hashValue = decodeURIComponent(location.hash.substr(1));
    //   this.setState({
    //     headline: hashValue
    //   });
    // });
    return () => {
      //unlisten();
    };
  }, [pathname, getHeadline, dispatch]);

  useEffect(() => {
    if (didMount.current) {
      getHeadline(pathname);
    } else {
      didMount.current = true;
    }
    getHeadline(pathname);
  }, [pathname, getHeadline]);

  const onClickChangePage = (page) => () => {
    changePage(page);
    iconClicked && setIconClicked(false);
    getHeadline(page);
  };

  const removeLocalStorageItems = () => {
    localStorage.removeItem('access-token');
    localStorage.removeItem('logs');
    localStorage.removeItem('displaySection');
    localStorage.removeItem('section');
  };

  const signOut = () => {
    removeLocalStorageItems();
    dispatch(userLogoutAction());
    changePage('/sign-in');
  };

  const navbarItemClassName = (page) => {
    const pageActive = pathname === page;
    return classNames({ navbarActive: pageActive });
  };

  const handleClick = () => {
    setIconClicked(!iconClicked);
  };

  return (
    <div className="navbar">
      {isMobile && (
        <Base64Image
          className="userProfilePicture just-mobile"
          type="user"
          imageID={omama.username}
          backUpImage="/images/cestavonLOGO_unofficial.png"
          updateAllowed={true}
        />
      )}
      <ul className="navbar_items">
        <h1
          className="admin_headline"
          id={iconClicked ? 'admin_headline_hidden' : undefined}
          data-test-id="menuHeadline"
        >
          {headline}
        </h1>
        <div className="navbar_center" id={iconClicked ? 'navbar_center_resp' : undefined}>
          <div className="respoMenu" onClick={handleClick}>
            {iconClicked ? <CloseOutlined className="respoMenu-closeIcon closeIcon" /> : ''}
          </div>
          {isAdmin && (
            <>
              <li
                className={navbarItemClassName('/admin')}
                onClick={onClickChangePage('/admin')}
                data-test-id="adminMenuStatistics"
              >
                {strings.statistics}
              </li>
              <li
                className={navbarItemClassName('/admin/users')}
                onClick={onClickChangePage('/admin/users')}
                data-test-id="adminMenuUsers"
              >
                {strings.users}
              </li>
              <li
                className={navbarItemClassName('/admin/klienti')}
                onClick={onClickChangePage('/admin/klienti')}
                data-test-id="adminMenuClients"
              >
                {strings.clients}
              </li>
              <li
                className={navbarItemClassName('/admin/aktivity')}
                onClick={onClickChangePage('/admin/aktivity')}
                data-test-id="adminMenuActivities"
              >
                {strings.activities}
              </li>
              <li
                className={navbarItemClassName('/admin/vyvinoveStadia')}
                onClick={onClickChangePage('/admin/vyvinoveStadia')}
                data-test-id="adminMenuDevelopmentStages"
              >
                {strings.developmentStages}
              </li>
              <li
                className={navbarItemClassName('/admin/pomocky')}
                onClick={onClickChangePage('/admin/pomocky')}
                data-test-id="adminMenuAccessories"
              >
                <div className="accessories-notification">
                  {strings.helperThings}
                  {missingAccessoriesCount && missingAccessoriesCount.count > 0 && (
                    <div className={notificationStyle}>{missingAccessoriesCount.count}</div>
                  )}
                </div>
              </li>
              <li
                className={navbarItemClassName('/admin/report')}
                onClick={onClickChangePage('/admin/report')}
                data-test-id="adminMenuReport"
              >
                {strings.report}
              </li>
            </>
          )}
          {isMentor && (
            <>
              <li
                className={navbarItemClassName('/admin')}
                onClick={onClickChangePage('/admin')}
                data-test-id="mentorMenuStatistics"
              >
                {strings.statistics}
              </li>
              <li
                className={navbarItemClassName('/admin/users')}
                onClick={onClickChangePage('/admin/users')}
                data-test-id="mentorMenuUsers"
              >
                {strings.omamy}
              </li>
              <li
                className={navbarItemClassName('/admin/plan')}
                onClick={onClickChangePage('/admin/plan')}
                data-test-id="mentorMenuPlan"
              >
                {strings.plan}
              </li>
              <li
                className={navbarItemClassName('/admin/klienti')}
                onClick={onClickChangePage('/admin/klienti')}
                data-test-id="mentorMenuClients"
              >
                {strings.clients}
              </li>
              <li
                className={navbarItemClassName('/admin/aktivity')}
                onClick={onClickChangePage('/admin/aktivity')}
                data-test-id="mentorMenuActivities"
              >
                {strings.activities}
              </li>
              <li
                className={navbarItemClassName('/admin/vyvinoveStadia')}
                onClick={onClickChangePage('/admin/vyvinoveStadia')}
                data-test-id="mentorMenuDevelopmentStages"
              >
                {strings.developmentStages}
              </li>
              <li
                className={navbarItemClassName('/mentor/vykaz')}
                onClick={onClickChangePage('/mentor/vykaz')}
                data-test-id="mentorMenuReport"
              >
                {strings.workReport}
              </li>
            </>
          )}
          {isSupervisor && (
            <>
              <li
                className={navbarItemClassName('/admin')}
                onClick={onClickChangePage('/admin')}
                data-test-id="supervisorMenuStatistics"
              >
                {strings.statistics}
              </li>
              <li
                className={navbarItemClassName('/admin/users')}
                onClick={onClickChangePage('/admin/users')}
                data-test-id="supervisorMenuUsers"
              >
                {strings.omamy}
              </li>
              <li
                className={navbarItemClassName('/admin/plan')}
                onClick={onClickChangePage('/supervisor/plan')}
                data-test-id="supervisorMenuPlan"
              >
                {strings.plan}
              </li>
              <li
                className={navbarItemClassName('/admin/klienti')}
                onClick={onClickChangePage('/admin/klienti')}
                data-test-id="supervisorMenuClients"
              >
                {strings.clients}
              </li>
              <li
                className={navbarItemClassName('/admin/aktivity')}
                onClick={onClickChangePage('/admin/aktivity')}
                data-test-id="supervisorMenuActivities"
              >
                {strings.activities}
              </li>
              <li
                className={navbarItemClassName('/admin/vyvinoveStadia')}
                onClick={onClickChangePage('/admin/vyvinoveStadia')}
                data-test-id="supervisorMenuDevelopmentStages"
              >
                {strings.developmentStages}
              </li>
            </>
          )}
          <li
            className={`${navbarItemClassName('/admin/nastavenia')} just-mobile`}
            onClick={onClickChangePage('/admin/nastavenia')}
            data-test-id="menuSettings"
          >
            {strings.settings}
          </li>
          <li id="sign_out_button" className="just-mobile" key="8" onClick={signOut} data-test-id="menuLogout">
            {strings.signOut}
          </li>
        </div>
      </ul>
      {!isMobile ? (
        <div className="user-action-container" onClick={() => setShowUserActions(true)} data-test-id="menuUserActions">
          <Base64Image
            className="userProfilePicture"
            type="user"
            imageID={omama.username}
            backUpImage="/images/cestavonLOGO_unofficial.png"
            updateAllowed={true}
          />
          <DownOutlined className="user-action-icon" />
        </div>
      ) : (
        <div className="menu" onClick={handleClick} data-test-id="menuOpen-mobile">
          {iconClicked ? (
            <CloseOutlined className="closeIcon" />
          ) : (
            <img className="burger" src="/images/Icons/burger.png" alt="" />
          )}
        </div>
      )}
      {showUserActions && (
        <div className="user-action-options">
          <div className="settings-option" onClick={onClickChangePage('/admin/nastavenia')} data-test-id="menuSettings">
            {strings.settings}
          </div>
          <div className="sign-out-option" onClick={signOut} data-test-id="menuLogout">
            {strings.signOut}
          </div>
        </div>
      )}
    </div>
  );
};

AdminMenu.propTypes = {
  pathname: PropTypes.string,
};

export default AdminMenu;
