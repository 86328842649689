import React from 'react';
import { useSelector } from 'react-redux';
import { Column, Line } from '@ant-design/plots';
import { strings } from '../../../strings/StringsProvider';

function StatisticsTrends() {
  const counts = useSelector((state) => state.statistics.counts);
  const screeningsByYear = useSelector((state) => state.statistics.screeningsByYear);
  const screeningsByLessonsCount = useSelector((state) => state.statistics.screeningsByLessonsCount);
  const parentEngagementByYear = useSelector((state) => state.statistics.parentEngagementByYear);
  const parentEngagementByLessonsCount = useSelector((state) => state.statistics.parentEngagementByLessonsCount);

  const renderTrendsCounts = (data) => {
    const config = {
      data,
      isGroup: true,
      xField: 'year',
      yField: 'count',
      seriesField: 'name',
      appendPadding: 50,
      label: {
        style: {
          fill: '#FFFFFF',
          opacity: 0.6,
        },
      },
      legend: {
        position: 'right',
      },
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: true,
        },
        title: {
          text: strings.year,
        },
      },
      yAxis: {
        title: {
          text: strings.numberOfActions,
        },
      },
    };
    return !data || data.length === 0 ? <div></div> : <Column {...config} />;
  };

  const renderTrendsScreenings = (data) => {
    const config = {
      data,
      isGroup: true,
      xField: 'year',
      yField: 'count',
      seriesField: 'name',
      appendPadding: 50,
      color: ({ name }) => {
        if (name === strings.upperGreenZone) {
          return 'green';
        }
        if (name === strings.upperOrangeZone) {
          return 'orange';
        }
        if (name === strings.upperRedZone) {
          return 'red';
        }
        return 'gray';
      },
      label: {
        content: (data) => {
          return data.percentage + '%';
        },
        style: {
          fill: '#FFFFFF',
          opacity: 0.6,
        },
      },
      legend: {
        position: 'right',
      },
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: true,
        },
        title: {
          text: 'rok',
        },
      },
      yAxis: {
        title: {
          text: strings.amountOfChildrens.toLowerCase(),
        },
      },
      tooltip: {
        title: strings.amountOfChildrens,
        customContent: (title, data) => {
          let list = '';
          data.forEach((row) => {
            list += row.data.name + ': ' + row.data.count + '<br>';
          });
          return `<div><strong>${title}</strong></div><br><div>${list}</div>`;
        },
      },
    };
    return !data || data.length === 0 ? <div></div> : <Column {...config} />;
  };

  const renderTrendsParentEngagement = (data) => {
    const config = {
      data,
      isGroup: true,
      isStack: true,
      xField: 'year',
      yField: 'count',
      seriesField: 'name',
      groupField: 'type',
      appendPadding: 50,
      color: ({ name }) => {
        if (name.includes(strings.veryOften.toLowerCase())) {
          return 'green';
        }
        if (name.includes(strings.sometimes.toLowerCase())) {
          return 'orange';
        }
        return 'red';
      },
      label: {
        content: (data) => {
          return data.percentage + '%';
        },
        style: {
          fill: '#FFFFFF',
          opacity: 0.6,
        },
      },
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: true,
        },
        title: {
          text: strings.year.toLowerCase(),
        },
      },
      yAxis: {
        title: {
          text: strings.amountOfChildrens.toLowerCase(),
        },
      },
      tooltip: {
        title: strings.amountOfChildrens,
        customContent: (title, data) => {
          let list = '';
          data.forEach((row) => {
            list += row.data.name + ': ' + row.data.count + '<br>';
          });
          return `<div><strong>${title}</strong></div><br><div>${list}</div>`;
        },
      },
    };
    return !data || data.length === 0 ? <div></div> : <Column {...config} />;
  };

  const renderTrendsParentEngagementLineGraph = (data) => {
    const config = {
      data,
      xField: 'year',
      yField: 'count',
      seriesField: 'name',
      appendPadding: 50,
      color: ({ name }) => {
        if (name.includes(strings.veryOften.toLowerCase())) {
          return 'green';
        }
        if (name.includes(strings.sometimes.toLowerCase())) {
          return 'orange';
        }
        return 'red';
      },
      label: {
        style: {
          fill: '#FFFFFF',
          opacity: 0.6,
        },
      },
      legend: {
        position: 'right',
      },
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: true,
        },
        title: {
          text: strings.year.toLowerCase(),
        },
      },
      yAxis: {
        title: {
          text: strings.countDoneLections.toLowerCase(),
        },
      },
      tooltip: {
        formatter: (data) => ({
          name: data.name,
          value: data.count,
        }),
      },
    };
    return !data || data.length === 0 ? <div></div> : <Line {...config} />;
  };

  const renderParentEngagementByClientLessonsCount = (data) => {
    data.sort((a, b) => a.countDoneLections - b.countDoneLections);
    const config = {
      data,
      xField: 'countDoneLections',
      yField: 'parentEngagementCount',
      seriesField: 'parentEngagementQuestionName',
      appendPadding: 50,
      color: ({ parentEngagementQuestionName }) => {
        if (parentEngagementQuestionName.includes(strings.veryOften.toLowerCase())) {
          return 'green';
        }
        if (parentEngagementQuestionName.includes(strings.sometimes.toLowerCase())) {
          return 'orange';
        }
        return 'red';
      },
      label: {
        style: {
          fill: '#FFFFFF',
          opacity: 0.6,
        },
      },
      legend: {
        position: 'right',
      },
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: true,
        },
        title: {
          text: strings.amountOfAbsolvedLectures.toLowerCase(),
        },
      },
      yAxis: {
        title: {
          text: strings.amountOfParents.toLowerCase(),
        },
      },
      tooltip: {
        formatter: (data) => ({
          name: data.parentEngagementQuestionName,
          value: data.parentEngagementCount,
        }),
      },
    };
    return !data || data.length === 0 ? <div></div> : <Line {...config} />;
  };

  const renderScreeningsByClientLessonsCount = (data) => {
    const config = {
      data,
      isGroup: true,
      xField: 'countDoneLections',
      yField: 'screeningPoints',
      seriesField: 'screeningType',
      appendPadding: 50,
      color: ({ screeningType }) => {
        if (screeningType === strings.upperGreenZone) {
          return 'green';
        }
        if (screeningType === strings.upperOrangeZone) {
          return 'orange';
        }
        return 'red';
      },
      label: {
        style: {
          fill: '#FFFFFF',
          opacity: 0.6,
        },
      },
      legend: {
        position: 'right',
      },
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: true,
        },
        title: {
          text: strings.amountOfAbsolvedLectures.toLowerCase(),
        },
      },
      yAxis: {
        title: {
          text: strings.amountOfPoints.toLowerCase(),
        },
      },
      tooltip: {
        formatter: (data) => ({
          name: data.screeningType,
          value: data.screeningPoints,
        }),
      },
    };
    return !data || data.length === 0 ? <div></div> : <Column {...config} />;
  };

  return (
    <div>
      <div className="clientsStats" data-test-id="statistics-trends-amountActions">
        <h4 className="clientsStats-header">{strings.amountOfActions}</h4>
        <div data-test-id="statistics-trends-amountActions-graph1">
          {counts ? (
            renderTrendsCounts(counts.filter((a) => a.name === strings.amountOfLessonsAndPreschoolClubs))
          ) : (
            <div></div>
          )}
        </div>
        <div data-test-id="statistics-trends-amountActions-graph2">
          {counts ? renderTrendsCounts(counts.filter((a) => a.name === strings.countParentalClub)) : <div></div>}
        </div>
      </div>
      <div className="clientsStats" data-test-id="statistics-trends-screening">
        <h4 className="clientsStats-header">{strings.developmentalScreening}</h4>
        <div>{renderTrendsScreenings(screeningsByYear)}</div>
        <div>
          {screeningsByLessonsCount ? renderScreeningsByClientLessonsCount(screeningsByLessonsCount) : <div></div>}
        </div>
      </div>
      <div className="clientsStats" data-test-id="statistics-trends-parentEngagement">
        <h4 className="clientsStats-header">{strings.parentEngagement}</h4>
        <div>{renderTrendsParentEngagement(parentEngagementByYear)}</div>
        <h4 className="clientsStats-header">{strings.parentEngagement1}</h4>
        <div>
          {parentEngagementByYear ? (
            renderTrendsParentEngagementLineGraph(parentEngagementByYear.filter((a) => a.type === 'question1'))
          ) : (
            <div></div>
          )}
        </div>
        <div>
          {parentEngagementByLessonsCount ? (
            renderParentEngagementByClientLessonsCount(
              parentEngagementByLessonsCount.filter((a) => a.parentEngagementQuestionNumber === 1),
            )
          ) : (
            <div></div>
          )}
        </div>
        <h4 className="clientsStats-header">{strings.parentEngagement2}</h4>
        <div>
          {parentEngagementByYear ? (
            renderTrendsParentEngagementLineGraph(parentEngagementByYear.filter((a) => a.type === 'question2'))
          ) : (
            <div></div>
          )}
        </div>
        <div>
          {parentEngagementByLessonsCount ? (
            renderParentEngagementByClientLessonsCount(
              parentEngagementByLessonsCount.filter((a) => a.parentEngagementQuestionNumber === 2),
            )
          ) : (
            <div></div>
          )}
        </div>
        <h4 className="clientsStats-header">{strings.parentEngagement3}</h4>
        <div>
          {parentEngagementByYear ? (
            renderTrendsParentEngagementLineGraph(parentEngagementByYear.filter((a) => a.type === 'question3'))
          ) : (
            <div></div>
          )}
        </div>
        <div>
          {parentEngagementByLessonsCount ? (
            renderParentEngagementByClientLessonsCount(
              parentEngagementByLessonsCount.filter((a) => a.parentEngagementQuestionNumber === 3),
            )
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );
}

export default StatisticsTrends;
